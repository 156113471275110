export enum GameName {
  Blackjack = 'Blackjack',
  CutAndSlice = 'Cut and slice',
  FeedTheSnake = 'Feed the snake',
  GolfMagic = 'Golf magic',
  KnivesOut = 'Knives out',
  MonsterMatch = 'Monster match',
  Solitaire = 'Solitaire',
  SpaceEscape = 'Space escape',
  Tetris = 'Tetris',
  WatchTheCup = 'Watch the cup',
  SaveTheFly = 'Save the Fly',
  Fingerball = 'Fingerball',
  Droppin = 'Droppin',
  CoinSwiper = 'Coin Swiper'
}

export enum GamePath {
  Blackjack = 'blackjack',
  CutAndSlice = 'cut-and-slice',
  FeedTheSnake = 'feed-the-snake',
  GolfMagic = 'golf-magic',
  KnivesOut = 'knives-out',
  MonsterMatch = 'monster-match',
  Solitaire = 'solitaire',
  SpaceEscape = 'space-escape',
  Tetris = 'tetris',
  WatchTheCup = 'watch-the-cup',
  SaveTheFly = 'save-the-fly',
  Fingerball = 'fingerball',
  Droppin = 'droppin',
  CoinSwiper = 'coin-swiper'
}

export enum GameRoutePath {
  Blackjack = 'blackjack',
  CutAndSlice = 'cut-and-slice',
  FeedTheSnake = 'feed-the-snake',
  GolfMagic = 'golf-magic',
  KnivesOut = 'knives-out',
  MonsterMatch = 'monster-match',
  Solitaire = 'solitaire',
  SpaceEscape = 'space-escape',
  Tetris = 'tetris',
  WatchTheCup = 'watch-the-cup',
  SaveTheFly = 'save-the-fly',
  Fingerball = 'fingerball',
  Droppin = 'droppin',
  CoinSwiper = 'coin-swiper'
}

export const GamePathByName: { [key in GameName]: GamePath } = {
  [GameName.Blackjack]: GamePath.Blackjack,
  [GameName.CutAndSlice]: GamePath.CutAndSlice,
  [GameName.FeedTheSnake]: GamePath.FeedTheSnake,
  [GameName.GolfMagic]: GamePath.GolfMagic,
  [GameName.KnivesOut]: GamePath.KnivesOut,
  [GameName.MonsterMatch]: GamePath.MonsterMatch,
  [GameName.Solitaire]: GamePath.Solitaire,
  [GameName.SpaceEscape]: GamePath.SpaceEscape,
  [GameName.Tetris]: GamePath.Tetris,
  [GameName.WatchTheCup]: GamePath.WatchTheCup,
  [GameName.SaveTheFly]: GamePath.SaveTheFly,
  [GameName.Fingerball]: GamePath.Fingerball,
  [GameName.Droppin]: GamePath.Droppin,
  [GameName.CoinSwiper]: GamePath.CoinSwiper,
}

export const GameRoutePathByName: { [key in GameName]: GameRoutePath } = {
  [GameName.Blackjack]: GameRoutePath.Blackjack,
  [GameName.CutAndSlice]: GameRoutePath.CutAndSlice,
  [GameName.FeedTheSnake]: GameRoutePath.FeedTheSnake,
  [GameName.GolfMagic]: GameRoutePath.GolfMagic,
  [GameName.KnivesOut]: GameRoutePath.KnivesOut,
  [GameName.MonsterMatch]: GameRoutePath.MonsterMatch,
  [GameName.Solitaire]: GameRoutePath.Solitaire,
  [GameName.SpaceEscape]: GameRoutePath.SpaceEscape,
  [GameName.Tetris]: GameRoutePath.Tetris,
  [GameName.WatchTheCup]: GameRoutePath.WatchTheCup,
  [GameName.SaveTheFly]: GameRoutePath.SaveTheFly,
  [GameName.Fingerball]: GameRoutePath.Fingerball,
  [GameName.Droppin]: GameRoutePath.Droppin,
  [GameName.CoinSwiper]: GameRoutePath.CoinSwiper,
}
